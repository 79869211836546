(function ($) {
    "use strict";

    //Hide Loading Box (Preloader)
    function handlePreloader() {
        if ($(".preloader").length) {
            $("body").addClass("page-loaded");
            $(".preloader").delay(300).fadeOut(0);
        }
    }

    //Update Header Style and Scroll to Top
    function headerStyle() {
        if ($(".main-header").length) {
            var windowpos = $(window).scrollTop();
            var siteHeader = $(".main-header");
            var sticky_header = $(".main-header .sticky-header");
            if (windowpos > 120) {
                siteHeader.addClass("fixed-header");
                sticky_header.addClass("animated slideInDown");
            } else {
                siteHeader.removeClass("fixed-header");
                sticky_header.removeClass("animated slideInDown");
            }
        }
    }

    headerStyle();

    //Submenu Dropdown Toggle
    if ($(".main-header li.dropdown ul").length) {
        $(".main-header .navigation li.dropdown > a").append('<div class="dropdown-btn"><span class="fa fa-angle-right"></span></div>');
    }

    //Mobile Nav Hide Show
    if ($(".side-menu__block").length) {
        var mobileMenuContent = $(".main-header .nav-outer .main-menu").html();
        var mobileNavContainer = $(".mobile-nav__container");
        mobileNavContainer.append(mobileMenuContent);

        //Dropdown Button
        mobileNavContainer.find("li.dropdown .dropdown-btn").on("click", function (e) {
            e.preventDefault();
            $(this).toggleClass("open");
            $(this).parent("a").parent("li").children("ul").slideToggle(500);
        });
        //Menu Toggle Btn
        $(".mobile-nav-toggler").on("click", function () {
            $(".side-menu__block").addClass("active");
        });

        $(".side-menu__block-overlay,.side-menu__toggler, .scrollToLink").on("click", function (e) {
            $(".side-menu__block").removeClass("active");
            e.preventDefault();
        });
    }

    //Search Popup
    if ($(".search-popup").length) {
        //Show Popup
        $(".search-toggler").on("click", function () {
            $(".search-popup").addClass("active");
        });
        //Hide Popup
        $(".search-popup__overlay").on("click", function (e) {
            $(".search-popup").removeClass("active");
            e.preventDefault();
        });
        //Hide Popup
        $(document).keydown(function (e) {
            if (e.keyCode === 27) {
                $(".search-popup").addClass("active");
            }
        });
    }

    //Custom Cursor
    if ($(".custom-cursor__overlay").length) {
        // / cursor /
        var cursor = $(".custom-cursor__overlay .cursor"),
            follower = $(".custom-cursor__overlay .cursor-follower");

        var posX = 0,
            posY = 0;

        var mouseX = 0,
            mouseY = 0;

        TweenMax.to({}, 0.016, {
            repeat: -1,
            onRepeat: function () {
                posX += (mouseX - posX) / 9;
                posY += (mouseY - posY) / 9;

                TweenMax.set(follower, {
                    css: {
                        left: posX - 22,
                        top: posY - 22,
                    },
                });

                TweenMax.set(cursor, {
                    css: {
                        left: mouseX,
                        top: mouseY,
                    },
                });
            },
        });

        $(document).on("mousemove", function (e) {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            mouseX = e.pageX;
            mouseY = e.pageY - scrollTop;
        });
        $("button, a").on("mouseenter", function () {
            cursor.addClass("active");
            follower.addClass("active");
        });
        $("button, a").on("mouseleave", function () {
            cursor.removeClass("active");
            follower.removeClass("active");
        });
        $(".custom-cursor__overlay").on("mouseenter", function () {
            cursor.addClass("close-cursor");
            follower.addClass("close-cursor");
        });
        $(".custom-cursor__overlay").on("mouseleave", function () {
            cursor.removeClass("close-cursor");
            follower.removeClass("close-cursor");
        });
    }

    //Main Slider / Banner Carousel
    if ($(".banner-carousel").length) {
        $(".banner-carousel").owlCarousel({
            loop: true,
            animateOut: "fadeOut",
            animateIn: "fadeIn",
            margin: 0,
            nav: true,
            smartSpeed: 500,
            autoplay: 6000,
            autoplayTimeout: 7000,
            navText: ['<div class="sr-only">Previous</div><span class="icon fa fa-angle-left"></span>', '<div class="sr-only">Next</div><span class="icon fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                800: {
                    items: 1,
                },
                992: {
                    items: 1,
                },
            },
        });
    }

    //Team Carousel
    if ($(".team-carousel").length) {
        $(".team-carousel").owlCarousel({
            loop: true,
            margin: 30,
            nav: true,
            smartSpeed: 700,
            autoplay: 5000,
            autoplayTimeout: 5000,
            navText: ['<span class="icon fa fa-angle-left"></span>', '<span class="icon fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                992: {
                    items: 3,
                },
                1200: {
                    items: 4,
                },
                1500: {
                    items: 4,
                },
                1600: {
                    items: 5,
                },
            },
        });
    }

    //Team Carousel
    if ($(".team-carousel__one-page").length) {
        $(".team-carousel__one-page").owlCarousel({
            loop: true,
            margin: 30,
            nav: true,
            smartSpeed: 700,
            autoplay: 5000,
            autoplayTimeout: 5000,
            navText: ['<span class="icon fa fa-angle-left"></span>', '<span class="icon fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                992: {
                    items: 3,
                },
                1200: {
                    items: 3,
                },
                1500: {
                    items: 3,
                },
                1600: {
                    items: 3,
                },
            },
        });
    }

    //Sponsors Carousel
    if ($(".sponsors-carousel").length) {
        $(".sponsors-carousel").owlCarousel({
            loop: true,
            margin: 30,
            nav: true,
            smartSpeed: 700,
            autoplay: 5000,
            autoplayTimeout: 5000,
            navText: ['<span class="icon fa fa-angle-left"></span>', '<span class="icon fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                768: {
                    items: 3,
                },
                992: {
                    items: 4,
                },
                1200: {
                    items: 5,
                },
            },
        });
    }

    //Project Carousel
    if ($(".project-carousel").length) {
        $(".project-carousel").owlCarousel({
            loop: true,
            margin: 30,
            nav: true,
            smartSpeed: 700,
            autoplay: 5000,
            navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1,
                },
                768: {
                    items: 2,
                },
                992: {
                    items: 3,
                },
                1200: {
                    items: 3,
                },
                1500: {
                    items: 4,
                },
                1600: {
                    items: 4,
                },
            },
        });
    }

    //Project Carousel
    if ($(".project-carousel-two").length) {
        $(".project-carousel-two").owlCarousel({
            loop: true,
            margin: 30,
            nav: true,
            smartSpeed: 700,
            autoplay: 5000,
            navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                768: {
                    items: 2,
                },
                992: {
                    items: 3,
                },
                1200: {
                    items: 3,
                },
            },
        });
    }

    //Testimonial Carousel
    if ($(".testimonials-carousel").length) {
        $(".testimonials-carousel").owlCarousel({
            loop: true,
            margin: 30,
            nav: true,
            smartSpeed: 700,
            autoplay: 5000,
            autoplayTimeout: 5000,
            navText: ['<span class="icon fa fa-angle-left"></span>', '<span class="icon fa fa-angle-right"></span>'],
            dots: false,
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                768: {
                    items: 1,
                },
                992: {
                    items: 2,
                },
                1200: {
                    items: 2,
                },
            },
        });
    }

    //Testimonial Carousel
    if ($(".testimonials-carousel-two").length) {
        $(".testimonials-carousel-two").owlCarousel({
            loop: true,
            margin: 30,
            nav: true,
            smartSpeed: 700,
            autoplay: 5000,
            autoplayTimeout: 5000,
            navText: ['<span class="icon fa fa-angle-left"></span>', '<span class="icon fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                768: {
                    items: 1,
                },
                1200: {
                    items: 1,
                },
            },
        });
    }

    //Single Item Carousel
    if ($(".single-item-carousel").length) {
        $(".single-item-carousel").owlCarousel({
            loop: true,
            margin: 30,
            nav: true,
            smartSpeed: 500,
            autoplay: 5000,
            autoplayTimeout: 5000,
            navText: ['<span class="icon fa fa-angle-left"></span>', '<span class="icon fa fa-angle-right"></span>'],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                800: {
                    items: 1,
                },
                1024: {
                    items: 1,
                },
            },
        });
    }

    // Donation Progress Bar
    if ($(".count-bar").length) {
        $(".count-bar").appear(
            function () {
                var el = $(this);
                var percent = el.data("percent");
                $(el).css("width", percent).addClass("counted");
            },
            {
                accY: -50,
            }
        );
    }

    //Fact Counter + Text Count
    if ($(".count-box").length) {
        $(".count-box").appear(
            function () {
                var $t = $(this),
                    n = $t.find(".count-text").attr("data-stop"),
                    r = parseInt($t.find(".count-text").attr("data-speed"), 10);

                if (!$t.hasClass("counted")) {
                    $t.addClass("counted");
                    $({
                        countNum: $t.find(".count-text").text(),
                    }).animate(
                        {
                            countNum: n,
                        },
                        {
                            duration: r,
                            easing: "linear",
                            step: function () {
                                $t.find(".count-text").text(Math.floor(this.countNum));
                            },
                            complete: function () {
                                $t.find(".count-text").text(this.countNum);
                            },
                        }
                    );
                }
            },
            {
                accY: 0,
            }
        );
    }

    //Jquery Knob animation
    if ($(".dial").length) {
        $(".dial").appear(
            function () {
                var elm = $(this);
                var color = elm.attr("data-fgColor");
                var perc = elm.attr("value");
                var thickness = elm.attr("data-thickness");

                elm.knob({
                    value: 0,
                    min: 0,
                    max: 100,
                    skin: "tron",
                    readOnly: true,
                    thickness: thickness,
                    dynamicDraw: true,
                    displayInput: false,
                });

                $({
                    value: 0,
                }).animate(
                    {
                        value: perc,
                    },
                    {
                        duration: 2000,
                        easing: "swing",
                        progress: function () {
                            elm.val(Math.ceil(this.value)).trigger("change");
                        },
                    }
                );
            },
            {
                accY: 0,
            }
        );
    }

    //Tabs Box
    if ($(".tabs-box").length) {
        $(".tabs-box .tab-buttons .tab-btn").on("click", function (e) {
            e.preventDefault();
            var target = $($(this).attr("data-tab"));

            if ($(target).is(":visible")) {
                return false;
            } else {
                target.parents(".tabs-box").find(".tab-buttons").find(".tab-btn").removeClass("active-btn");
                $(this).addClass("active-btn");
                target.parents(".tabs-box").find(".tabs-content").find(".tab").fadeOut(0);
                target.parents(".tabs-box").find(".tabs-content").find(".tab").removeClass("active-tab");
                $(target).fadeIn(300);
                $(target).addClass("active-tab");
            }
        });
    }

    //Product Tabs
    if ($(".project-tab").length) {
        $(".project-tab .product-tab-btns .p-tab-btn").on("click", function (e) {
            e.preventDefault();
            var target = $($(this).attr("data-tab"));

            if ($(target).hasClass("actve-tab")) {
                return false;
            } else {
                $(".project-tab .product-tab-btns .p-tab-btn").removeClass("active-btn");
                $(this).addClass("active-btn");
                $(".project-tab .p-tabs-content .p-tab").removeClass("active-tab");
                $(target).addClass("active-tab");
            }
        });
    }

    //Accordion Box
    if ($(".accordion-box").length) {
        $(".accordion-box").on("click", ".acc-btn", function () {
            var outerBox = $(this).parents(".accordion-box");
            var target = $(this).parents(".accordion");

            if ($(this).next(".acc-content").is(":visible")) {
                //return false;
                $(this).removeClass("active");
                $(this).next(".acc-content").slideUp(300);
                $(outerBox).children(".accordion").removeClass("active-block");
            } else {
                $(outerBox).find(".accordion .acc-btn").removeClass("active");
                $(this).addClass("active");
                $(outerBox).children(".accordion").removeClass("active-block");
                $(outerBox).find(".accordion").children(".acc-content").slideUp(300);
                target.addClass("active-block");
                $(this).next(".acc-content").slideDown(300);
            }
        });
    }

    //Custom Seclect Box
    if ($(".custom-select-box").length) {
        $(".custom-select-box").selectmenu().selectmenu("menuWidget").addClass("overflow");
    }

    //Datepicker
    if ($(".date-picker").length) {
        $(".date-picker").datepicker();
    }

    //LightBox / Fancybox
    if ($(".lightbox-image").length) {
        $(".lightbox-image").fancybox({
            openEffect: "fade",
            closeEffect: "fade",
            helpers: {
                media: {},
            },
        });
    }

    //MixitUp Gallery Filters
    if ($(".filter-list").length) {
        $(".filter-list").mixItUp({});
    }

    // Scroll to a Specific Div
    if ($(".scroll-to-target").length) {
        $(".scroll-to-target").on("click", function () {
            var target = $(this).attr("data-target");
            // animate
            $("html, body").animate(
                {
                    scrollTop: $(target).offset().top,
                },
                1000
            );

            return false;
        });
    }

    // Elements Animation
    if ($(".wow").length) {
        var wow = new WOW({
            boxClass: "wow", // animated element css class (default is wow)
            animateClass: "animated", // animation css class (default is animated)
            offset: 0, // distance to the element when triggering the animation (default is 0)
            mobile: false, // trigger animations on mobile devices (default is true)
            live: true, // act on asynchronously loaded content (default is true)
        });
        wow.init();
    }

    function SmoothMenuScroll() {
        var anchor = $(".scrollToLink");
        if (anchor.length) {
            anchor.children("a").bind("click", function (event) {
                if ($(window).scrollTop() > 10) {
                    var headerH = "0";
                } else {
                    var headerH = "0";
                }
                var target = $(this);
                $("html, body")
                    .stop()
                    .animate(
                        {
                            scrollTop: $(target.attr("href")).offset().top - headerH + "px",
                        },
                        1200,
                        "easeInOutExpo"
                    );
                anchor.removeClass("current");
                target.parent().addClass("current");
                event.preventDefault();
            });
        }
    }
    SmoothMenuScroll();

    function OnePageMenuScroll() {
        var windscroll = $(window).scrollTop();
        if (windscroll >= 117) {
            var menuAnchor = $(".one-page-scroll-menu .scrollToLink").children("a");
            menuAnchor.each(function () {
                // grabing section id dynamically
                var sections = $(this).attr("href");
                $(sections).each(function () {
                    // checking is scroll bar are in section
                    if ($(this).offset().top <= windscroll + 100) {
                        // grabing the dynamic id of section
                        var Sectionid = $(sections).attr("id");
                        // removing current class from others
                        $(".one-page-scroll-menu").find("li").removeClass("current");
                        // adding current class to related navigation
                        $(".one-page-scroll-menu")
                            .find("a[href*=\\#" + Sectionid + "]")
                            .parent()
                            .addClass("current");
                    }
                });
            });
        } else {
            $(".one-page-scroll-menu li.current").removeClass("current");
            $(".one-page-scroll-menu li:first").addClass("current");
        }
    }

    /* ==========================================================================
     When document is Scrollig, do
     ========================================================================== */

    $(window).on("scroll", function () {
        headerStyle();
        OnePageMenuScroll();
        if ($(".scroll-to-top").length) {
            var strickyScrollPos = 100;
            if ($(window).scrollTop() > strickyScrollPos) {
                $(".scroll-to-top").fadeIn(500);
            } else if ($(this).scrollTop() <= strickyScrollPos) {
                $(".scroll-to-top").fadeOut(500);
            }
        }
    });

    /* ==========================================================================
     When document is Resized, do
     ========================================================================== */

    $(window).on("resize", function () {});

    /* ==========================================================================
     When document is loading, do
     ========================================================================== */

    $(window).on("load", function () {
        handlePreloader();
    });
})(window.jQuery);
